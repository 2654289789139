
.timer div:first-child {
    border: 1px solid;
    border-radius: 3px;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin-top: 2em;
    padding: 2em;
    text-align: center;
    color: #D6B159;
}

section p:first-child, .timer div:last-child span {
    font-size: 3em;
}

@media screen and (max-width: 480px) {
    .timer div:first-child {
        padding: 2em;
    }
    section p:first-child, .timer div:last-child span {
        font-size: 2em;
     /* margin-left: 2em;*/
    /*margin-right: 2em;*/
    /*padding: 3em 1em;  */
    }
    
}