body {
   
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.05rem;
  }
  h1 {
      font-family: 'Dancing Script', cursive;
      line-height: 1,5em;
     text-shadow: .5px .5px 1px rgb(7, 7, 7);
     color:#D6B159;
  }
  h2, h3, h4 {
   
    color:#D6B159;
     
  }
  
  h4, h5, h6 {
    font-family: 'Open Sans', sans-serif;
  
    font-weight: lighter;
  }
  
  p {
    font-family: 'Open Sans', sans-serif;
      font-style: italic;
  }
   .btn {
     background-color: rgb(255, 127, 234);
   }
   .btn:hover {
    background-color: rgb(218, 45, 189);
  }
  
  
  
  #logo {
    width: 30px;
    margin-right: 15px;
  }
  
   /* section unde si cand
  #geolocal {
    background-image: linear-gradient(90deg, #F3B8CA , #ecb0e0 95%);
  }
  #geolocal h1 {
    color: aliceblue;
  }
  
     section unde si cand*/
  
  
  
    /* css blog*/
  
  .main-row {
   
      margin-bottom: 8%;
      background-color:rgba(255, 255, 255, 0.705);
      box-shadow: 0 0 10px 10px rgba(0,0,0,.05);
      border-radius: 0.5rem;
      
    }
    
    .blog-img > img {
     width: 250px;
     transform: translateY(-30px);
     border-radius: 50%;
     box-shadow: 0 0 8px 3px rgba(0,0,0,.3);
    }
    .blog-title > h3 {
      line-height: 2rem;
    }
    @media (min-width: 500px) {
    .blog-title > h3 {
      line-height: 3rem;
    }
    }
    @media (min-width: 1100px) {
      .main-row {
        max-width: 800px;
        position: relative;
        left: 15%;
      }
      }